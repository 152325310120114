import Utils from 'common/scripts/utils';

class TabsAjax {
    constructor() {
        /**
         * Шаблон для контента
         * @type {null}
         */
        this.template = null;

        /**
         * Место куда будет вставлен новый контент
         * @type {null}
         */
        this.content = null;

        /**
         * Массив всех табов
         * @type {Array}
         */
        this.tabs = null;
    }

    init({target, content, template}) {
        this.target = target;
        this.content = content;
        this.template = template;
        this.moreBtn = document.querySelector('.j-more');
        this.tabs = Array.from(document.querySelectorAll('.j-tabs__item'));

        this.bindClick();
    }

    bindClick() {
        this.tabs.forEach(tab => {
            tab.addEventListener('click', event => {
                const requestUrl = tab.dataset.send;

                event.preventDefault();

                if (!tab.classList.contains('b-tabs__item_is_active')) {
                    this.download(requestUrl, tab);
                }
            });
        });
    }

    download(requestUrl, tabElement) {
        Utils.send({}, requestUrl, {
            success: response => {
                const event = new CustomEvent('tabLoaded');

                Utils.clearHtml(this.content);
                Utils.insetContent(this.content, this.template(response));
                if (this.moreBtn) {
                    this.moreBtn.dataset.send = response.btnLink;
                    this.moreBtn.style.display = response.isEnd ? 'none' : 'block';
                }
                this.content.dispatchEvent(event);

                this.tabs.forEach(tab => {
                    tab.classList.remove('b-tabs__item_is_active');
                });
                tabElement.classList.add('b-tabs__item_is_active');
                history.pushState(null, null, tabElement.getAttribute('data-link'));
            }
        });
    }
}

export default TabsAjax;
