/* eslint-disable max-lines-per-function,no-magic-numbers,max-len,camelcase */
import './jquery.eventCalendar.js';
import './moment.js';

class Calendar {
    /**
     * Инициализирует компонент
     * @param {Object} calendar - объект настроек
     */
    init(calendar) {

        const datasets = JSON.parse(calendar.dataset.datasets);

        $(() => {
            const data = datasets;

            $(calendar).eventCalendar({
                jsonData            : data,
                jsonDateFormat      : 'human',
                startWeekOnMonday   : false,
                openEventInNewWindow: true,
                dateFormat          : 'DD-MM-YYYY',
                showDescription     : false,
                locales             : {
                    locale                  : 'ru',
                    txt_noEvents            : 'Нет запланированных событий',
                    txt_SpecificEvents_prev : '',
                    txt_SpecificEvents_after: 'события:',
                    txt_NextEvents          : 'Следующие события:',
                    txt_GoToEventUrl        : 'Смотреть',
                    moment                  : {
                        months       : ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                        monthsShort  : ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                        weekdays     : ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                        weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                        weekdaysMin  : ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                    }
                }
            });
        });
    }
}

export default Calendar;
