class Tabs {
    constructor() {
        this.target = null;
        this.parent = null;
    }

    init({target}) {
        target.parentNode.classList.add('b-tabs-parent');
    }
}

export default Tabs;
