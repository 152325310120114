/**
 * @version 1.2
 * @author Kelnik Studios {http://kelnik.ru}
 * @link https://kelnik.gitbooks.io/kelnik-documentation/content/front-end/components/spoiler.html documentation
 */

/**
 * DEPENDENCIES
 */
import Utils from 'common/scripts/utils';

class Spoiler {
    constructor() {
        /**
         * Нода спойлера
         * @type {node}
         */
        this.spoiler = {};

        /**
         * Высота спойлера в закрытом состоянии
         * @type {null}
         */
        this.height = null;

        /**
         * Высота контента в открытом состояни
         * @type {null}
         */
        this.contentHeight = null;

        /**
         * Текст кнопки когда спойлер закрыт
         * @type {string}
         */
        this.textClose = ``;

        /**
         * Текст кнопки когда спойлер открыт
         * @type {string}
         */
        this.textOpen = ``;

        /**
         * Плавное затемнение(забеление) текста снизу
         * @type {boolean}
         */
        this.blackout = null;

        /**
         * Объект элементов спойлера
         * @type {object}
         */
        this.elements = {};

        /**
         * Состояние спойлера - открыт (true), закрыт (false)
         * @type {boolean}
         */
        this.isOpen = null;

        /**
         * Класс открытого слайдера
         * @type {string}
         */
        this.isActive = ``;

        /**
         * Количество строк контента, отображаемое при закрытом спойлере, если установлена опция dynamicHeight
         * @type {number}
         */
        this.linesAmount = null;

        /**
         * Флаг указаывающие на то что высота закртого спойлера должна подстраитваься под высоту строк контента
         * @type {boolean}
         */
        this.dynamicHeight = false;
    }

    /**
     * Инициализируем спойлер
     * @param {object} options - объект настроек спойлера
     */
    init(options) {
        const defaultLinesAmount = 9;

        this.spoiler = options.target;
        this.height = Utils.isString(options.height) ? parseInt(options.height, 10) : options.height;
        this.textClose = this.spoiler.dataset.textClose || `Показать`;
        this.textOpen = this.spoiler.dataset.textOpen || `Скрыть`;
        this.isActive = options.activeClass || `is-active`;
        this.blackout = options.blackout || false;
        this.isOpen = options.open || false;
        this.dynamicHeight = options.dynamicHeight || false;
        this.linesAmount = options.linesAmount || defaultLinesAmount;

        this._getElements();
        this._changeSpoiler();
        this._bindEvents();
        this._windowResize();
    }

    /**
     * Получаем ноды элементов спойлера
     * @private
     */
    _getElements() {
        this.elements.wrap = this.spoiler.firstElementChild;
        this.elements.content = this.elements.wrap.firstElementChild;
        this.elements.button = this.spoiler.lastElementChild;
        this.elements.text = this.elements.button.firstElementChild;
        this.elements.textContent = this.elements.content.querySelector('p') ||
            this.elements.content.querySelector('.b-spoiler__content-wrap');

        if (this.blackout) {
            this._createBlackout();
        }
    }

    /**
     * Высчиываем высоту закрытого спойлера в зависимости от количества отображаемых строк
     * @returns {string} - строка со значением высоты контента в пикселях
     * @private
     */
    _getDynamicHeight() {
        let textLineHeight = 0;

        if (getComputedStyle(this.elements.textContent).lineHeight === 'normal') {
            const defaultLineHeight = 1.2;
            const lh = parseInt(getComputedStyle(this.elements.textContent).fontSize, 10) * defaultLineHeight;

            textLineHeight = parseInt(lh, 10);
        } else {
            textLineHeight = parseInt(getComputedStyle(this.elements.textContent).lineHeight, 10);
        }

        const textOffset = this.elements.textContent.offsetTop;

        return (textLineHeight * this.linesAmount) + textOffset;
        // return `${(textLineHeight * this.linesAmount) + textOffset}px`;
    }

    /**
     * Получаем состояние спойлера.
     * @returns {boolean} - true если высота контента больше высоты спойлера
     * @private
     */
    _stateSpoiler() {
        this.contentHeight = this.elements.content.clientHeight;

        if (this.dynamicHeight) {
            return this.contentHeight > this._getDynamicHeight();
        }

        return this.contentHeight > this.height;
    }

    /**
     * Изменяем DOM элементы спойлера
     * @private
     */
    _changeSpoiler() {
        if (this.blackout) {
            this._changeBlackout();
        }

        this._setWrapHeight();
        this._changeStateButton();
    }

    /**
     * Вешаем слушатель на кнопаньку
     * @private
     */
    _bindEvents() {
        this.elements.button.addEventListener('click', this.toggleSpoiler.bind(this));
    }

    /**
     * Добавляем/убираем класс модификатор на кнопку спойлера
     * @private
     */
    _changeButtonClass() {
        if (this.isOpen) {
            this.elements.button.classList.add(this.isActive);

            return;
        }

        this.elements.button.classList.remove(this.isActive);
    }

    /**
     * Задаем высоту обуртке контента
     * @private
     */
    _setWrapHeight() {
        if (!this._stateSpoiler()) {
            this.elements.wrap.style.height = `auto`;

            return;
        }

        if (this.isOpen) {
            this.elements.wrap.style.height = `${this.contentHeight}px`;

            return;
        }
        if (this.dynamicHeight && this.elements.textContent) {
            this.elements.wrap.style.height = `${this._getDynamicHeight()}px`;

            return;
        }

        this.elements.wrap.style.height = `${this.height}px`;
    }

    /**
     * Создаем элемент плавного исчезновения текста в спойлере
     * @private
     */
    _createBlackout() {
        this.blackoutNode = document.createElement('div');
        Utils.insetContent(this.elements.wrap, this.blackoutNode);
    }

    /**
     * меняем состояние видимости элемента
     * @private
     */
    _changeBlackout() {
        if (this.isOpen || !this._stateSpoiler()) {
            Utils.hide(this.blackoutNode);

            return;
        }

        Utils.show(this.blackoutNode);
    }

    /**
     * Задаем текст кнопке
     * @private
     */
    _setButtonText() {
        if (this.isOpen) {
            this.elements.text.innerHTML = this.textClose;

            return;
        }

        this.elements.text.innerHTML = this.textOpen;
    }

    /**
     * Показываем/скрываем кнопку
     * @private
     */
    _changeStateButton() {
        if (this._stateSpoiler()) {
            Utils.show(this.elements.button);

            return;
        }

        Utils.hide(this.elements.button);
    }

    /**
     * Меняем состояние спойлера открыто/закрыто
     * @private
     */
    _stateChange() {
        if (this.blackout) {
            this._changeBlackout();
        }

        this._setWrapHeight();
        this._setButtonText();
        this._changeButtonClass();
    }

    /**
     * переключаем состояние спойлера с открытого на закрытое и наоборот
     */
    toggleSpoiler() {
        this.isOpen = !this.isOpen;

        this._stateChange();
    }

    /**
     * Закрываем спойлер
     */
    closeSpoiler() {
        if (!this.isOpen) {
            return;
        }

        this.isOpen = !this.isOpen;

        this._stateChange();
    }

    /**
     * Открываем спойлер
     */
    openSpoiler() {
        if (this.isOpen) {
            return;
        }

        this.isOpen = !this.isOpen;

        this._stateChange();
    }

    _windowResize() {
        window.addEventListener('resize', () => {
            this._changeSpoiler();
        });
    }
}

export default Spoiler;
