import 'selectric';
import $ from 'jquery';

import axios from 'axios';
import errorTemplate from './templates/errorTemplate.twig';
import Mediator from 'common/scripts/mediator';
import optionsTemplate from './templates/optionsTemplate.twig';
import resultListTemplate from './templates/resultListTemplate.twig';
import resultTabListTemplate from './templates/resultTabListTemplate.twig';
import Utils from 'common/scripts/utils';
import yearsTemplate from './templates/yearsTemplate.twig';

const mediator = new Mediator();

class DocumentsFilter {
    constructor(options) {
        this.form = options.filter;
        this.errorTemplate = errorTemplate;
        this.optionsTemplate = optionsTemplate;
        this.yearsTemplate = yearsTemplate;
        this.resultTemplateType = options.template;
        this.select = this.form.querySelector('select');
        this.inputs = null;
        this.inputsWrap = document.querySelector('.j-documents__filter');
        this.action = this.form.getAttribute('action');
        this.resultContent = null;
        this.prevResultContent = null;
        this.isTabsForm = options.isTabsForm;
        this.isSearch = false;
        this.loadingClass = 'is-loading';
        this.hiddenClass = 'is-hidden';
    }

    /**
     * Инит формы
     * @private
     */
    init() {
        this._subscribes();
        this._setResultTemplate();
        this._initSelect();
        this._getElements(null);
        this._bindEvents();
    }

    /**
     * События при смене таба
     * @private
     */
    _subscribes() {
        // отработает перед сменой таба
        mediator.subscribe('beforeTabChange', () => {
            if (this.isSearch) {
                this._savePrevWrap();
                this._resetFilters();
                this._resetSelect();
                this._send('reset');
            }
        });

        // отработает при смене таба и при ините (!) табов
        mediator.subscribe('tabChange', activeTab => {
            this._setCategoryId(activeTab);
            this._send('companiesList');
            this._resetSearch();
            this._getElements(activeTab);
        });
    }

    /**
     * Устанавливает шаблон отображения данных
     * @private
     */
    _setResultTemplate() {
        if (this.resultTemplateType) {
            switch (this.resultTemplateType) {
                case 'tabList':
                default:
                    this.resultTemplate = resultTabListTemplate;
                    break;
                case 'list':
                    this.resultTemplate = resultListTemplate;
                    break;
            }
        }
    }

    /**
     * Инит селекта
     * @private
     */
    _initSelect() {
        $(this.select).selectric({
            disableOnMobile: false,
            nativeOnMobile : false,
            onChange       : () => {
                this._resultHandler();
            }
        });
    }

    /**
     * Получает элементы: контейнер в текущем активном табе / контейнер
     * @param {object} activeTab - активный таб
     * @private
     */
    _getElements(activeTab) {
        this.resultContent =
            activeTab ? activeTab.querySelector('.j-search-result') : document.querySelector('.j-search-result');
    }

    /**
     * Навешивает события
     * @private
     */
    _bindEvents() {
        if (!this.isTabsForm) {
            this._setCategoryId();
            this._send('companiesList');
        }
    }

    /**
     * Управляет загрузкой результата
     * @private
     */
    _resultHandler() {
        this._runLoading();
        this._setSearch();
        this._send('filter');
        this._endLoading();
    }

    /**
     * Отправляет запрос
     * @param {String} requestType - тип запроса
     * @param {String} category - ИД активного таба
     * @private
     */
    _send(requestType, category = this.caterogyId) {
        this.formData = new FormData(this.form);

        this.formData.append('requestType', requestType);
        this.formData.append('categoryId', category);

        if (requestType === 'companiesList') {
            this.formData.delete('company');
        }

        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        axios.post(this.action, this.formData)
            .then(response => {
                const data = response.data;
                const {companies, years} = data;

                if (data) {
                    switch (requestType) {
                        default:
                            this._showResult(data);
                            break;
                        case 'companiesList':
                            // eslint-disable-next-line no-unused-expressions
                            companies ? this._updateFilter(companies, years) : this._hideFilter();
                            break;
                    }

                    if (years) {
                        this._updateYearsFilter(years);
                    }
                } else {
                    throw new Error('Ошибка на сервере');
                }
            })
            .catch(err => {
                this._sendIsError();
                console.error(err);
            });
    }

    _updateYearsFilter(years) {
        const year = Object.values(years);

        // eslint-disable-next-line
        this.inputs.filter(input => input.disabled = year.indexOf(input.id) === -1);
    }

    _updateFilter(companies, years) {
        this._updateCompaniesList(companies);
        this._updateYearsList(years);
        this._yearsFilterHandler();
    }

    /**
     * Обновляет список доступных компаний в селекте в данной категории (табе)
     * @param {object} companiesList - список доступных компаний
     * @private
     */
    _updateCompaniesList(companiesList) {
        if (this.form.classList.contains(this.hiddenClass)) {
            this.form.classList.remove(this.hiddenClass);
        }

        $(this.select).empty()
            .append(this.optionsTemplate({companiesList}))
            .selectric('refresh');
    }

    _updateYearsList(yearsList) {
        Utils.clearHtml(this.inputsWrap);
        Utils.insetContent(this.inputsWrap, this.yearsTemplate({yearsList}));
    }

    _yearsFilterHandler() {
        this.inputs = Array.from(this.form.querySelectorAll('input'));

        this.inputs.forEach(input => {
            input.addEventListener('change', this._resultHandler.bind(this));
        });
    }

    /**
     * Скрывает форму при отсутствии списка компаний
     * @private
     */
    _hideFilter() {
        this.form.classList.add(this.hiddenClass);
    }

    /**
     * Выводит результат
     * @param {object} data - объект данных
     * @private
     */
    _showResult(data) {
        const wrapper = this.prevResultContent ? this.prevResultContent : this.resultContent;

        this._clearData(wrapper);

        Utils.insetContent(wrapper, this.resultTemplate(data));
        this._deletePrevWrap();
    }

    /**
     * Выводит сообщение об ошибке
     * @private
     */
    _sendIsError() {
        this._clearData(this.resultContent);
        this._showErrorMessage();
    }

    /**
     * Показывает сообщение об ошибке
     * @private
     */
    _showErrorMessage() {
        Utils.insetContent(this.resultContent, this.errorTemplate());
    }

    /**
     * Устанавливает флаг фильтрации: если была фильтрация, то при смене таба нужно ре-рендерить контент на начальный
     * @private
     */
    _setSearch() {
        if (this.isTabsForm) {
            this.isSearch = true;
        }
    }

    /**
     * Проставляет CSS-класс загрузки
     * @private
     */
    _runLoading() {
        this.resultContent.classList.add(this.loadingClass);
    }

    /**
     * Удаляет CSS-класс загрузки
     * @private
     */
    _endLoading() {
        this.resultContent.classList.remove(this.loadingClass);
    }

    _setCategoryId(tab) {
        // eslint-disable-next-line no-unused-expressions
        tab ? this.caterogyId = tab.dataset.tab : this.caterogyId = 'inner';
    }

    /**
     * Снимает активность инпутов при смене табов
     * @private
     */
    _resetFilters() {
        this.inputs.forEach(input => {
            if (input.checked) {
                input.checked = !input.checked;
            }
        });
    }

    /**
     * Ресетит селект
     */
    _resetSelect() {
        $(this.select)
            // eslint-disable-next-line no-magic-numbers
            .prop('selectedIndex', 0)
            .selectric('refresh');
    }

    /**
     * Снимает флаг фильтрации
     * @private
     */
    _resetSearch() {
        if (this.isTabsForm) {
            this.isSearch = false;
        }
    }

    /**
     * Очищает документы
     * @param {Object} wrapper - Нода обертки для вставки результата
     * @private
     */
    _clearData(wrapper) {
        Utils.clearHtml(wrapper);
    }

    _savePrevWrap() {
        this.prevResultContent = this.resultContent;
    }

    _deletePrevWrap() {
        this.prevResultContent = null;
    }
}

export default DocumentsFilter;
