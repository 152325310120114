class GroupCompany {
    constructor() {
        /**
         * Угол поворота
         * @type {number}
         */
        this.angle = 90;

        /**
         * Состояние круглежка
         * активное(true) или неактивное(false)
         * @type {boolean}
         */
        this.active = false;

        /**
         * Всего сторон у круга
         * @type {number}
         */
        this.sides = 4;

        /**
         * Элемент который будет вращаться
         * @type {null}
         */
        this.arc = null;

        /**
         * Элемент с картинкой
         * @type {null}
         */
        this.imageElement = null;

        this.logoElement = null;
        this.logoElementDefaultValue = '';

        this.activeClass = 'is-active';
    }

    init({target, sides}) {
        const circleAngle = 360;

        this.target = target;
        this.arc = target.querySelector('.b-group-company__arc');
        this.imageElement = target.querySelector('.b-group-company__image');
        this.sides = sides;
        this.angle = circleAngle / this.sides;
        this.logoElement = target.querySelector('.b-group-company__logo-image');
        this.logoElementDefaultValue = this.logoElement.src;
    }

    /**
     *
     * @param {number} part - к какой часте круга повернуться
     */
    toPart(part) {
        this.arc.style.transform = `rotate(${this.getAngle(part)}deg)`;
    }

    /**
     * Возвращает угол на который следут повернуть this.arc
     * @param {number} part - часть круга от 0 до 4
     * @return {number} - угол наклона
     */
    getAngle(part) {
        return this.angle * part;
    }

    /**
     * добавляет активное сосотояние
     */
    activateState() {
        this.changeState(true);
    }

    /**
     * Удаляет активное состояние
     */
    disactivateState() {
        this.changeState(false);
    }

    /**
     * Изменяет состояние
     * @param {Boolean} state - состояние компонента
     */
    changeState(state) {
        if (state) {
            this.target.classList.add(this.activeClass);
        } else {
            this.target.classList.remove(this.activeClass);
        }
    }

    changeLogo(src) {
        this.logoElement.src = src;
    }

    /**
     * Изменяет картинку
     * @param {String} url - путь до картинки
     */
    changeImage(url) {
        // console.log(url)
        this.imageElement.style.backgroundImage = `url(${url})`;
    }

    /**
     * Устанавливает индекс в аттрибуты
     * @return {string} - индекс
     */
    getIndex() {
        return this.arc.dataset.index;
    }

    /**
     * Устанавливает индекс в аттрибуты
     * @param {number} index - индекc
     */
    setIndex(index) {
        this.arc.dataset.index = index;
    }
}

export default GroupCompany;
