/**
 * @author Kelnik
 *
 * Validation - Проверка инпутов на валидность
 */

import InputMask from 'inputmask';

class Validation {
    constructor(options = {}, messages = {}) {
        // eslint-disable-next-line
        this.options = Object.assign({
            formSuccess     : 'validate-form-success',
            formError       : 'validate-form-error',
            inputSuccess    : 'validate-input-success',
            inputError      : 'validate-input-error',
            inputKey        : 'validate-input-key',
            phoneMask       : '+7 (999) 999 99-99',
            emailReg        : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
            numberReg       : /^\d+$/,
            lettersReg      : /^[a-zа-яё]+$/i,
            urlReg          : /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, // eslint-disable-line
            keyDelay        : 500,
            separatorPrice  : ' ',
            separatorDecimal: ',',
            decimalDigits   : 1
        }, options);

        // eslint-disable-next-line
        this.messages = Object.assign({
            required: 'Поле обязятельно для заполнения',
            min     : 'Мин $ симлвола',
            max     : 'Длина строки не больше $',
            email   : 'Введите эл. почту',
            number  : 'Только числа',
            letters : 'Только буквы',
            url     : 'Не корректный url',
            tel     : 'Не корректный номер телефона',
            regexp  : 'Не соответствует формату',
            mask    : 'Заполните поле полностью'
        }, messages);

        this.forms = [];
        this.inputs = [];
        this.formInputsSelector = 'input:not([type="submit"]):not([type="hidden"]):not([type="button"]), textarea';

        this.init();
    }

    init() {
        this._helpers();
        this._initElements();
        this._filterInputsIntoForm();
        this._initInputMask();

        this._bindEvents();
    }

    update() {
        this._initElements();
        this._filterInputsIntoForm();
        this._initInputMask();

        this._bindEvents();
    }

    _helpers() {
        // eslint-disable-next-line
        String.prototype.replaceAll = function(search, replace){
            return this.split(search).join(replace);
        };
    }

    _initElements() {
        this.forms = Array.from(document.querySelectorAll('form[data-validate]'));
        this.inputs = Array.from(document.querySelectorAll('input[data-validate], textarea[data-validate]'));
    }

    /**
     * Фильтруем все инпуты которые будут валидироваться в форме
     */
    _filterInputsIntoForm() {
        this.inputs = this.inputs.filter((input) => {
            return !input.closest('form');
        });
    }

    /**
     * Добавляем маски для телефонов и кастомные (через аттрибут)
     */
    _initInputMask() {
        const optionsMask = {showMaskOnHover: false};
        const phone = (input) => {
            new InputMask(this.options.phoneMask, optionsMask).mask(input);
        };
        const customMask = (input) => {
            const mask = input.getAttribute('data-validate-mask');

            new InputMask(mask, optionsMask).mask(input);
        };

        const giveMasks = (input) => {
            if (input.hasAttribute('data-validate-mask')) {
                customMask(input);
            } else if (input.type === 'tel' || input.hasAttribute('data-validate-tel')) {
                phone(input);
            }
        };

        this.inputs.forEach((input) => {
            giveMasks(input);
        });

        this.forms.forEach((form) => {
            const inputs = form.querySelectorAll(this.formInputsSelector);

            inputs.forEach((input) => {
                giveMasks(input);
            });
        });
    }

    _bindEvents() {
        this.forms.forEach((form) => {
            if (!this._verifyDoubleInit(form)) {
                return;
            }

            const inputs = Array.from(form.querySelectorAll(this.formInputsSelector));
            const typeCheck = form.dataset.validateCheck;
            const inputKeys = inputs.filter((input) => {
                return input.dataset.validateKeyOnly || input.dataset.validatePrice;
            });

            if (typeCheck === 'key') {
                this._inputsEvents(inputs);
            } else if (inputKeys.length) {
                this._inputsEvents(inputKeys);
            }

            this._replaceDefaultValid(inputs);

            form.addEventListener('submit', (event) => {
                event.preventDefault();

                let valid = true;

                inputs.forEach((input) => {
                    const validate = this._validateInput(input);
                    const name = input.dataset.validateName;
                    const error = document.querySelector(`[data-validate-error="${name}"]`);

                    if (validate.result) {
                        this._inputSuccess(input, error);
                    } else {
                        valid = false;
                        this._inputError(input, error, validate);
                    }
                });

                if (valid) {
                    this._formSuccess(form);
                } else {
                    this._formError(form);
                }
            });
        });

        this._replaceDefaultValid(this.inputs);
        this._inputsEvents(this.inputs);
    }

    /**
     * Бинд событий для инпутов
     * @param {Array} inputs - Элементы инпутов
     */
    // eslint-disable-next-line max-lines-per-function
    _inputsEvents(inputs) {
        // eslint-disable-next-line max-lines-per-function
        inputs.forEach((input) => {
            if (!this._verifyDoubleInit(input)) {
                return;
            }

            const name = input.dataset.validateName;
            const error = document.querySelector(`[data-validate-error="${name}"]`);
            const keyOnly = input.dataset.validateKeyOnly;
            const isPrice = input.hasAttribute('data-validate-price');
            const isDecimal = input.hasAttribute('data-validate-decimal');
            let timerKeyup = null;
            const validateInput = () => {
                const validate = this._validateInput(input);

                if (validate.result) {
                    this._inputSuccess(input, error);
                } else {
                    this._inputError(input, error, validate);
                }
            };

            if (keyOnly) {
                this._onlyKeyEvents(input, keyOnly);
            }

            if (isPrice) {
                this._formatPrice(input);
            } else if (isDecimal) {
                this._formatDecimal(input);
            }

            input.addEventListener('keyup', () => {
                validateInput(input);
            });

            input.addEventListener('paste', () => {
                setTimeout(() => {
                    validateInput(input);
                }, 0);
            });

            input.addEventListener('cut', () => {
                setTimeout(() => {
                    validateInput(input);
                }, 0);
            });

            input.addEventListener('input', () => {
                timerKeyup = this._inputsAddKeyClass(input, timerKeyup);
            });

            input.addEventListener('change', () => {
                const validate = this._validateInput(input);

                if (validate.result) {
                    this._inputSuccess(input, error);
                } else {
                    this._inputError(input, error, validate);
                }
            });
        });
    }

    /**
     * Вешает события на ввод инпутам, ввод только допустимых симловов
     * @param {Node} input - Элемент инпута
     * @param {string} keyOnly - Строка key'сов для ввода
     */
    _onlyKeyEvents(input, keyOnly) {
        const keysRaw = keyOnly.split(',').map((key) => {
            if (Number(key)) {
                return Number(key);
            }

            return key.split('-').map((keyItem) => {
                return Number(keyItem);
            });
        });
        // key after filter
        const keys = keysRaw.filter((key) => {
            return Number(key) || key.filter((k) => {
                return Boolean(k);
            }).length;
        });

        input.addEventListener('keypress', (event) => {
            let stop = true;

            keys.forEach((key) => {
                if (Array.isArray(key)) {
                    if (event.charCode >= key[0] && event.charCode <= key[1]) {
                        stop = false;
                    }
                } else if (event.charCode === key) {
                    stop = false;
                }
            });

            // eslint-disable-next-line no-unused-expressions
            stop && event.preventDefault();
        });

        input.addEventListener('paste', (event) => {
            event.preventDefault();
        });
    }

    _formatPrice(input) {
        const separator = input.dataset.validatePrice || this.options.separatorPrice;

        input.addEventListener('keypress', (event) => {
            if (!(event.charCode >= 48 && event.charCode <= 57)) {
                event.preventDefault();
            }
        });

        input.addEventListener('input', () => {
            const val = input.value.replaceAll(separator, '', 'g');

            if (!val) {
                return;
            }

            input.value = this.toFormatPrice(val, separator);
        });

        input.addEventListener('paste', (event) => {
            event.preventDefault();
        });
    }

    toFormatPrice(value, separator = ' ') {
        const val = (value / 1).toFixed(0).replace('.', ',');

        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    _formatDecimal(input) {
        const separator = input.dataset.validateDecimal || this.options.separatorDecimal;
        const digits = Number(input.dataset.validateDecimalDigits) || this.options.decimalDigits;

        new InputMask({
            regex          : `\\d+(\\${separator}\\d{${digits}})?`,
            placeholder    : '',
            showMaskOnHover: false
        }).mask(input);

        input.addEventListener('focusout', () => {
            const arr = input.value.split(separator);

            if (arr[1]) {
                input.value = `${arr[0]}${separator}${arr[1] + '0'.repeat(digits - arr[1].length)}`;
            } else if (arr[0]) {
                input.value = `${input.value}${separator}${'0'.repeat(digits)}`;
            }
        });
    }

    /**
     * Добавляет класс при вводе текста
     * @param {Node} input - Элемент инпута
     * @param {function} timer - Таймер прерыва ввода
     * @return {function} - Таймер прерыва ввода
     */
    _inputsAddKeyClass(input, timer) {
        input.classList.add(this.options.inputKey);

        clearTimeout(timer);

        return setTimeout(() => {
            input.classList.remove(this.options.inputKey);
        }, this.options.keyDelay);
    }

    /**
     * Проверяем на повторную инициализацию и добавляем аттр data-init
     * @param {Node} element - элемент
     * @return {Boolean} - True если элемент не инициализировался
     */
    _verifyDoubleInit(element) {
        if (element.dataset.init) {
            return false;
        }

        element.setAttribute('data-init', true);

        return true;
    }

    /**
     * Заменяет аттрибут required на data-validate-required (для откл. стандартной валидации)
     * @param {Array} inputs - элементы инпутов
     */
    _replaceDefaultValid(inputs) {
        inputs.forEach((input) => {
            if (input.required) {
                input.removeAttribute('required');
                input.setAttribute('data-validate-required', '');
            }

            if (input.type === 'tel') {
                input.type = 'text';
                input.setAttribute('data-validate-tel', '');
            }

            if (input.type === 'email') {
                input.type = 'text';
                input.setAttribute('data-validate-email', '');
            }

            if (input.type === 'number') {
                input.type = 'text';
                input.setAttribute('data-validate-number', '');
            }

            if (input.type === 'url') {
                input.type = 'text';
                input.setAttribute('data-validate-url', '');
            }
        });
    }

    /**
     * Проверка инпута на валидацию (по дата аттрибутам)
     * @param {Node} input - Инпут для валидации
     * @return {object} - Результат валидации
     */
    // eslint-disable-next-line max-lines-per-function,complexity,max-statements
    _validateInput(input) {
        if (input.hasAttribute('data-validate-required')) {
            if (!this._validateRequired(input)) {
                const message = input.dataset.validateRequiredMsg || this.messages.required;

                return {
                    result: false,
                    error : 'required',
                    message
                };
            }
        }

        // eslint-disable-next-line max-len
        if (input.hasAttribute('data-validate-mask') || input.hasAttribute('data-validate-tel') || input.type === 'tel') {
            if (!this._validateMask(input)) {
                const message = input.dataset.validateMaskMsg || this.messages.mask;

                return {
                    result: false,
                    error : 'mask',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-regexp')) {
            if (!this._validateRegexp(input)) {
                const message = input.dataset.validateRegexpMsg || this.messages.regexp;

                return {
                    result: false,
                    error : 'regexp',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-email') || input.type === 'email') {
            if (!this._validateEmail(input)) {
                const message = input.dataset.validateEmailMsg || this.messages.email;

                return {
                    result: false,
                    error : 'email',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-number') || input.type === 'number') {
            if (!this._validateNumber(input)) {
                const message = input.dataset.validateNumberMsg || this.messages.number;

                return {
                    result: false,
                    error : 'number',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-letters')) {
            if (!this._validateLetters(input)) {
                const message = input.dataset.validateLettersMsg || this.messages.letters;

                return {
                    result: false,
                    error : 'string',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-url')) {
            if (!this._validateUrl(input)) {
                const message = input.dataset.validateUrlMsg || this.messages.url;

                return {
                    result: false,
                    error : 'url',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-min')) {
            if (!this._validateMin(input)) {
                const message = input.dataset.validateMinMsg || this.messages.min;

                return {
                    result: false,
                    error : 'min',
                    message
                };
            }
        }

        if (input.hasAttribute('data-validate-max')) {
            if (!this._validateMax(input)) {
                const message = input.dataset.validateMaxMsg || this.messages.max;

                return {
                    result: false,
                    error : 'max',
                    message
                };
            }
        }

        return {result: true};
    }

    _checkIfEmptyNotRequired(input) {
        return !input.value && !input.dataset.validateReqired;
    }

    _validateRequired(input) {
        if (input.type === 'checkbox') {
            return input.checked;
        }

        if (input.type === 'radio') {
            const form = input.closest('form');
            const container = form ? form : document;

            return container.querySelector(`input[name="${input.name}"]:checked`);
        }

        return Boolean(input.value);
    }

    _validateMask(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        return input.inputmask.isComplete();
    }

    _validateRegexp(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        const attr = input.dataset.validateRegexp;
        const reArray = attr.split('/');

        if (attr[0] !== '/' || reArray.length !== 3) {
            console.error('Не верный формат regexp');

            return false;
        }

        return new RegExp(reArray[1], reArray[2]).test(input.value);
    }

    _validateMin(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        const min = input.dataset.validateMin;

        if (!Number(min)) {
            console.error('Attribute "data-validate-min" is not number');

            return false;
        }

        return Number(input.value.length) >= Number(min);
    }

    _validateMax(input) {
        const max = input.dataset.validateMax;

        if (!Number(max)) {
            console.error('Attribute "data-validate-max" is not number');

            return false;
        }

        return Number(input.value.length) <= Number(max);
    }

    _validateEmail(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        return this.options.emailReg.test(input.value.toLowerCase());
    }

    _validateNumber(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        return this.options.numberReg.test(input.value);
    }

    _validateLetters(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        return this.options.lettersReg.test(input.value);
    }

    _validateUrl(input) {
        if (this._checkIfEmptyNotRequired(input)) {
            return true;
        }

        return this.options.urlReg.test(input.value);
    }

    /**
     * Успешная валидация, убираем вывод ошибок
     * @param {Node} input - Элемент интпут
     * @param {Node} error - Элемент вывода ошибки
     */
    _inputSuccess(input, error) {
        if (error) {
            this._hideError(error);
        }

        this._setSuccessClassInput(input);
    }

    /**
     * Неудачная валидация, выводим ошибоки
     * @param {Node} input - Элемент интпут
     * @param {Node} error - Элемент вывода ошибки
     * @param {object} validate - Инфо об ошибки
     */
    _inputError(input, error, validate) {
        if (error) {
            this._replaceTextError(input, error, validate);
            this._showError(error);
        } else {
            console.warn('Element for display error not found!');
        }

        this._setErrorClassInput(input);
    }

    /**
     * Делает замену текста ошибки (подставляет значение с min, max)
     * @param {Node} input - Элемент интпут
     * @param {Node} error - Элемент вывода ошибки
     * @param {object} validate - Инфо об ошибки
     */
    _replaceTextError(input, error, validate) {
        const errorName = `${validate.error[0].toUpperCase()}${validate.error.slice(1)}`;
        const value = input.dataset[`validate${errorName}`];
        const message = value ? validate.message.replace(/\$/gi, value) : validate.message;

        error.innerHTML = `${message}`;
    }

    _hideError(error) {
        error.style.display = 'none';
    }

    _showError(error) {
        error.style.display = 'block';
    }

    _setSuccessClassInput(input) {
        input.classList.remove(this.options.inputError);
        input.classList.add(this.options.inputSuccess);

        input.setAttribute('validity', '');
    }

    _setErrorClassInput(input) {
        input.classList.remove(this.options.inputSuccess);
        input.classList.add(this.options.inputError);

        input.removeAttribute('validity');
    }

    /**
     * При успешной валидации
     * @param {Node} form - элемент формы
     */
    _formSuccess(form) {
        form.classList.remove(this.options.formError);
        form.classList.add(this.options.formSuccess);
        form.setAttribute('validity', '');
    }

    /**
     * При ошибках в валидации
     * @param {Node} form - элемент формы
     */
    _formError(form) {
        form.classList.remove(this.options.formSuccess);
        form.classList.add(this.options.formError);
        form.removeAttribute('validity');
    }

    /**
     * При ошибках в валидации
     * @param {Node} content - Контейнер или сама форма которую нужно обновить
     */
    refresh(content) {
        if (!content) {
            return;
        }

        let forms = Array.from(content.querySelectorAll('form[data-init]'));

        if (!forms.length && content.tagName === 'form') {
            forms = [content];
        }

        forms.forEach((form) => {
            form.removeAttribute('data-init');

            form.querySelectorAll(this.formInputsSelector).forEach((input) => {
                input.removeAttribute('data-init');
            });
        });

        if (forms.length) {
            this.update();
        }
    }
}

export default Validation;
