import 'components/disable-hover';
import AlertOldBrowser from 'components/alert-old-browser';
import DocumentsFilter from 'components/investoram-search-form'
import Form from 'components/form';
import Tabs from 'components/tabs-content';

const alertOldBrowser = new AlertOldBrowser();

alertOldBrowser.init();

export const initForm = wrapper => {
    new Form().init({
        target             : wrapper,
        successMessage     : true,
        successFromTemplate: wrapper.dataset.successTemplate,
        successTemplateType: wrapper.dataset.successTemplate
    });
};

// Табы
export const initTabs = elemItem => {
    new Tabs().init({form: elemItem});
};

// Фильтр по документам
export const initDocumentsFilter = options => {
    new DocumentsFilter(options).init();
};
