class Vacancy {
    constructor() {
        this.target = null;
        this.activeClass = 'is-active';
    }

    init({target}) {
        this.target = target;

        this.bindClick();
    }

    bindClick() {
        this.target.addEventListener('click', () => {
            this.target.classList.toggle(this.activeClass);
        });
    }
}

export default Vacancy;
