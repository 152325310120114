import Utils from 'common/scripts/utils';

class More {
    constructor() {
        /**
         * Кнопка по которой будут кликать ,чтобы добавить новый контент
         * @type {null}
         */
        this.target = null;

        /**
         * Шаблон для контента
         * @type {null}
         */
        this.template = null;

        /**
         * Место куда будет вставлен новый контент
         * @type {null}
         */
        this.content = null;

        /**
         * Урл до сервера
         * @type {null}
         */
        this.requestUrl = null;

        /**
         * Страница по счёту
         * информация для сервера
         * @type {number}
         */
        this.number = 1;
    }

    init({target, content, template}) {
        this.target = target;
        this.content = content;
        this.template = template;
        this.requestUrl = this.target.dataset.send;
        this.bindClick();
    }

    bindClick() {
        this.target.addEventListener('click', event => {
            event.preventDefault();
            this.download();
        });
    }

    download() {
        this.requestUrl = this.target.dataset.send;
        Utils.send({number: this.number}, this.requestUrl, {
            success: response => {
                const nextPage = 1;

                this.number = this.number + nextPage;
                Utils.insetContent(this.content, this.template(response));
                this.hideButton(response.isEnd);
            }
        });
    }

    /**
     * Скрывает кнопку если сервер сказал, что больше не будет результатов
     * @param {Boolean} hide - true || false
     */
    hideButton(hide) {
        if (hide) {
            this.target.style.display = 'none';
        }
    }

    resetPage() {
        this.number = 1;
    }
}

export default More;
