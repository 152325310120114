import Mediator from '../../common/scripts/mediator';

const emptyFunction = () => {
    return null;
};
const mediator = new Mediator();

class Tabs {
    constructor() {
        /**
         * Обертка табов
         * нужна на случай множественных табов на странице
         * по дефолту - document
         * @type {Node}
         */
        this.elem = null;

        /**
         * Надо ли открывать таб по хэшу
         * @type {Boolean}
         */
        this.hash = false;

        /**
         * Идентификатор таба, который нужно показать первым
         * если нет хэша, первым будет показан этот таб
         * если его нет, будет показан первый таб или таб с активным классом
         * @type {String}
         */
        this.initialTab = null;

        /**
         * Родительская нода табов
         * @type {Node}
         */
        this.tabsParent = null;

        /**
         * Массив табов
         * @type {Array}
         */
        this.tabs = [];

        /**
         * Родительская нода контента
         * @type {Node}
         */
        this.contentsParent = null;

        /**
         * Массив блоков контента
         * @type {Array}
         */
        this.contents = [];

        /**
         * Атрибуты активного таба
         * @type {Object}
         */
        this.dataTab = {};

        /**
         * Класс активного таба
         * @type {String}
         */
        this.activeClass = 'is-active';
    }

    /**
     * Инициализация
     * @param {Object} options - параметры
     */
    init(options) {
        this._setOptions(options);
        this._setInitialState();
        this._bindContext();
        this._bindEvents();
    }

    /**
     * Применяет парамерты
     * @param {Object} options - параметры
     * @private
     */
    _setOptions(options) {
        this.elem = options.elem || document;
        this.hash = options.hash && true;

        this.initialTab = options.initialTab || null;

        this.onChangeCallback = options.onChangeCallback || emptyFunction;

        this.tabsParent = this.elem.querySelector(options.tabsParent || '.j-tabs') || null;
        this.tabItem = options.tabItem || '.j-tabs__item';
        this.tabs = Array.from(this.tabsParent.querySelectorAll(this.tabItem));

        this.contentsParent = this.elem.querySelector(options.contentsParent || '.j-tabs-content') || null;
        this.contentItem = options.contentItem || '.j-tabs-content__item';
        this.contents = Array.from(this.contentsParent.querySelectorAll(this.contentItem));
    }

    /**
     * Ставит начальное состояние
     * @private
     */
    _setInitialState() {
        let tab = this.tabsParent.querySelector(`${this.tabItem}.${this.activeClass}`) || this.tabs[0];

        if (this.hash) {
            const hash = this._getHash();

            tab = hash ? this.tabsParent.querySelector(`${this.tabItem}[data-tab="${hash}"]`) || tab : tab;
        } else if (this.initialTab) {
            tab = this.tabsParent.querySelector(`${this.tabItem}[data-tab="${this.initialTab}"]`) || tab;
        }

        this.toggleTab(tab);
    }

    /**
     * Получает значение хэша
     * @returns {String} - значение хэша
     * @private
     */
    _getHash() {
        return window.location.hash.replace(/^#/u, '');
    }

    /**
     * Привязывает контекст
     * @private
     */
    _bindContext() {
        this._onTabs = this._onTabs.bind(this);
    }

    /**
     * Биндит события
     * @private
     */
    _bindEvents() {
        this.tabsParent.addEventListener('click', this._onTabs);
    }

    /**
     * Обрабатывает событие клика на таб
     * @param {Object} event - объект события
     * @private
     */
    _onTabs(event) {
        event.preventDefault();
        mediator.publish('beforeTabChange');

        if (!event.target || !event.target.closest(this.tabItem)) {
            return;
        }

        this.toggleTab(event.target.closest(this.tabItem));
    }

    /**
     * Переключает таб
     * @param {Node} tab - нода активного таба
     */
    toggleTab(tab) {
        this._setData(tab);
        this._changeState();

        this.onChangeCallback(this.dataTab);
    }

    /**
     * Записывает атрибуты активного таба
     * @param {Node} tab - нода активного таба
     * @private
     */
    _setData(tab) {
        if (!tab) {
            return;
        }
        this.dataTab = {
            tab,
            id: tab.dataset.tab
        };
    }

    /**
     * Переключает классы всем задействованным элементам
     * @private
     */
    _changeState() {
        const {id} = this.dataTab;

        this.tabs.forEach((elem) => {
            elem.classList.toggle(this.activeClass, elem.dataset.tab === id);
        });
        this.contents.forEach((elem) => {
            elem.classList.toggle(this.activeClass, elem.dataset.tab === id);

            if (elem.dataset.tab === id) {
                mediator.publish('tabChange', elem);
            }
        });
    }
}

export default Tabs;
