/* eslint-disable line-comment-position,no-inline-comments,max-statements,max-lines-per-function,no-magic-numbers,no-magic-numbers,no-magic-numbers */
import 'selectric';
import './common';
import './../styles/app.scss';
import {Chart, registerables} from 'chart.js';
import {
    initDocumentsFilter,
    initForm,
    initTabs
} from 'common/scripts/common';
import Calendar from 'components/calendar';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GroupCompany from 'components/group-company';
import HistoryToggle from 'components/history-feed';
import IndexInfo from 'components/index-info';
import Mediator from 'common/scripts/mediator';
import MenuTrigger from 'components/menu-trigger';
import MobileMenu from 'components/mobile-menu';
import More from 'components/more';
import objectFitImages from 'object-fit-images';
import Popup from 'components/popup';
import Slider from 'components/slider';
import Spoiler from 'components/spoiler';
import Tabs from 'components/tabs';
import TabsAjax from 'components/tabs/tabs-ajax';
import templateHistory from 'components/history-feed/history-feed.twig';
import templateObjectsTab from 'components/tabs/templates/objects-tab.twig';
import templatePressCenterPublication from 'components/publication-press-center/publication-press-center.twig';
import templateVideo from 'components/video/video-template.twig';
import templateVideoTab from 'components/tabs/templates/video-tab.twig';
import Utils from 'common/scripts/utils';
import Vacancy from 'components/vacancy';
import Validation from 'components/form/validation';
import YandexMap from 'components/yandex-map';
import yandexMapLoad from 'components/yandex-map/load';

objectFitImages(document.querySelectorAll('.j-object-fit img'));
new Validation(); // eslint-disable-line no-new

Chart.register(...registerables);

/**
 * Полифил конструктора CustomEvent для IE
 */
/* eslint-disable */
const polyfillCustomEvent = function customEvent() {
    try {
        new CustomEvent('IE has CustomEvent, but doesn\'t support constructor');
    } catch (e) {
        window.CustomEvent = function (event, params) {
            let evt;
            params = params || {
                bubbles: false,
                cancelable: false,
                detail: undefined
            };
            evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

            return evt;
        };

        CustomEvent.prototype = Object.create(window.Event.prototype);
    }
};
polyfillCustomEvent();
/* eslint-enable */

const mediator = new Mediator();

// меню
const mobileMenu = new MobileMenu();

Array.from(document.querySelectorAll('.j-menu-trigger')).forEach(target => {
    const menuTrigger = new MenuTrigger();

    menuTrigger.init({target});
});

mobileMenu.init({target: document.querySelector('.j-mobile-menu')});

const $body = document.querySelector('body');
const overlay = document.querySelector('.j-overlay');

mediator.subscribe('menuTrigger:open', () => {
    mobileMenu.open();
    $body.classList.add('fixed-for-menu');
    overlay.classList.add('is-active');
});

mediator.subscribe('menuTrigger:close', () => {
    mobileMenu.close();
    $body.classList.remove('fixed-for-menu');
    overlay.classList.remove('is-active');
});


/* Затемнение фона при наведении на меню */

const navItem = Array.from(document.querySelectorAll('.j-layout__nav .b-navigation__item'));
const headerForOverlay = document.querySelector('.j-layout__header');

if (headerForOverlay) {
    navItem.forEach(item => {
        if (!item.querySelector('.b-navigation__submenu')) {
            return;
        }

        item.addEventListener('mouseenter', () => {
            overlay.classList.add('is-active');
            headerForOverlay.classList.add('header-is-hover');
        });

        item.addEventListener('mouseleave', () => {
            overlay.classList.remove('is-active');
            headerForOverlay.classList.remove('header-is-hover');
        });
    });
}

const homeSlider = Array.from(document.querySelectorAll('.j-home-slider'));
const homeText = [].slice.call(document.querySelectorAll('.j-index-info'));

if (homeSlider.length) {
    homeSlider.forEach(item => {
        const slides = new Slider();

        slides.init({
            target  : item,
            infinite: true
        });
    });
}

if (homeText.length) {
    const indexInfo = new IndexInfo({elements: homeText});

    mediator.subscribe('slideIndex', numberSlide => {
        indexInfo.change(numberSlide);
    });
}

const imageSlider = Array.from(document.querySelectorAll('.j-image-slider'));

if (imageSlider.length) {
    imageSlider.forEach(item => {
        const slides = new Slider();

        slides.init({
            target  : item,
            infinite: true
        });
    });
}

/* eslint-disable */
const initMaps = function () {
    const mapWrapper = Array.from(document.querySelectorAll('.j-yandex-map'));

    if (mapWrapper.length) {
        yandexMapLoad()
            .then((ymaps) => {
                mapWrapper.forEach((item) => {
                    const yandexMap = new YandexMap(ymaps);

                    yandexMap.init({wrapper: item});
                });
            })
            .catch((error) => {
                console.error(`При загрузке яндекс карт произошла ошибка: ${error}`);
            });
    }
};

initMaps();
/* eslint-enable */


// Показать ещё новостей
const moreButtonNews = document.querySelector('.b-publication-list__more.j-more');

if (moreButtonNews) {
    const more = new More();

    more.init({
        target  : moreButtonNews,
        content : document.querySelector('.b-publication-list__publications'),
        template: templatePressCenterPublication
    });
}

// Показать ещё объектов
const moreButtonObjects = document.querySelector('.b-objects-list__more.j-more');
let moreObjects = null;

if (moreButtonObjects) {
    moreObjects = new More();

    moreObjects.init({
        target  : moreButtonObjects,
        content : document.querySelector('.b-objects-list__content'),
        template: templateObjectsTab
    });
}

// Показать ещё видео
const moreButtonVideo = document.querySelector('.l-video__show-more.j-more');
let moreVideo = null;

if (moreButtonVideo) {
    moreVideo = new More();

    moreVideo.init({
        target  : moreButtonVideo,
        content : document.querySelector('.l-video__content'),
        template: templateVideo
    });
}

// Показать ещё историй
const moreButtonHistory = document.querySelector('.l-history__more.j-more');

if (moreButtonHistory) {
    const more = new More();

    more.init({
        target  : moreButtonHistory,
        content : document.querySelector('.b-history-feed'),
        template: templateHistory
    });
}

const initAwardsSlider = (elements, number) => {
    if (elements.length) {
        elements.forEach(item => {
            const slides = new Slider();

            slides.init({
                target         : item,
                infinite       : true,
                initSlideNumber: number
            });
        });
    }
};

const popups = document.querySelectorAll('.j-popup-awards');

if (popups.length) {
    Array.from(popups).forEach(popupElement => {
        const popup = new Popup();

        popup.init({target: popupElement});
    });
}

mediator.subscribe('openPopup', popup => {
    const one = 1;
    const sliderArray = Array.from(document.querySelectorAll('.b-popup .j-awards-slider'));
    const targetPopupNumber = Utils.getElementIndex(popup.target.parentElement) + one;

    initAwardsSlider(sliderArray, targetPopupNumber);
});

if (!window.matchMedia('(min-width: 670px)').matches) {
    initAwardsSlider(Array.from(document.querySelectorAll('.j-awards-slider')));
}

// Вакансии
const vacancies = document.querySelectorAll('.b-vacancy');

Array.from(vacancies).forEach(target => {
    const vacancy = new Vacancy();

    vacancy.init({target});
});

// Круглежок
const circle = new GroupCompany();
const circles = Array.from(document.querySelectorAll('.b-group-info_theme_desktop .b-group-info__list li.item'));

if (circles.length) {
    circle.init({
        target: document.querySelector('.b-group-company_theme_desktop'),
        sides : circles.length
    });

    circles.forEach(element => {
        element.addEventListener('mouseenter', () => {
            const index = circles.indexOf(element);
            const logoSrc = element.querySelector('.b-group-company-icon__icon img').src;

            circle.changeImage(element.dataset.image);
            circle.activateState();
            circle.changeLogo(logoSrc);
            circle.toPart(index);
        });

        element.addEventListener('mouseleave', () => {
            const index = circles.indexOf(element);

            circle.changeLogo(circle.logoElementDefaultValue);
            circle.disactivateState();
            circle.toPart(index);
        });
    });
}

// Слайдер компаний на мобильных устройствах
const compantSlider = document.querySelector('.j-company-slider');

if (compantSlider) {
    const slider = new Slider();
    const circleMobile = new GroupCompany();
    const circlesMobile = Array.from(document.querySelectorAll('.b-group-info_theme_mobile li'));
    const circleImageContainer = document.querySelector('.b-group-company__image');

    circleMobile.init({
        target: document.querySelector('.b-group-company_theme_mobile'),
        sides : circlesMobile.length
    });

    slider.init({
        target  : compantSlider,
        infinite: true
    });

    if (!circleImageContainer.style.backgroundImage) {
        const index = 0;

        circleMobile.changeImage(circlesMobile[index].dataset.image);
        circleMobile.activateState();
    }

    mediator.subscribe('slide', () => {
        const last = 1;
        const index = slider.activeSlide - last;
        const logoSrc = circlesMobile[index].querySelector('.b-group-company-icon__icon img').src;

        circleMobile.changeImage(circlesMobile[index].dataset.image);
        circleMobile.activateState();
        circleMobile.toPart(index);
        circleMobile.changeLogo(logoSrc);
    });
}

const tabsWrapper = document.querySelector('.b-tabs');

if (tabsWrapper) {
    const tab = new Tabs();

    tab.init({target: tabsWrapper});
}

// Табы для видео
const videoTabs = document.querySelector('.l-video__tabs');

if (videoTabs) {
    const tab = new TabsAjax();
    const tabContent = document.querySelector('.l-video__content');

    tab.init({
        target  : videoTabs,
        content : tabContent,
        template: templateVideoTab
    });

    tabContent.addEventListener('tabLoaded', () => {
        if (moreVideo) {
            moreVideo.resetPage();
        }
    });
}

// Табы для объектов
const objectsTabs = document.querySelector('.b-objects-list__tabs');

if (objectsTabs) {
    const tab = new TabsAjax();
    const tabContent = document.querySelector('.b-objects-list__content');

    tab.init({
        target  : objectsTabs,
        content : tabContent,
        template: templateObjectsTab
    });

    tabContent.addEventListener('tabLoaded', () => {
        if (moreObjects) {
            moreObjects.resetPage();
        }
    });
}

// Табы для контактов
const contactsTabs = document.querySelector('.l-contacts__tabs');

if (contactsTabs) {
    /* eslint-disable */
    const tabs = Array.from(document.querySelectorAll('.j-tabs__item'));

    const sendTabRequest = function (url, callback = null) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');
        xhr.send();

        xhr.onload = function () {
            if (xhr.status === 200) {
                document.querySelector('.j-tab-content').innerHTML = xhr.responseText;
                history.pushState(null, null, url);

                if (callback !== null) {
                    callback();
                }
            }
        };
    };
    /* eslint-enable */

    tabs.forEach(element => {
        element.addEventListener('click', () => {
            const dataLink = element.getAttribute('data-link');

            sendTabRequest(dataLink, initMaps);
            tabs.forEach(el => {
                el.classList.remove('b-tabs__item_is_active');
            });
            element.classList.add('b-tabs__item_is_active');
        });
    });
}

// Показать/скрыть всю историю компании
const historyFeed = document.querySelector('.b-history-feed');

if (historyFeed) {
    const historyToggle = new HistoryToggle();

    historyToggle.init();
}


const spoilerNodeArray = [].slice.call(document.querySelectorAll('.j-spoiler'));

if (spoilerNodeArray.length) {
    spoilerNodeArray.forEach(spoilerNode => {
        const spoiler = new Spoiler();

        spoiler.init({
            target       : spoilerNode,
            height       : 130,
            blackout     : true,
            dynamicHeight: true
        });
    });
}

const spoilerNodeArrayTwo = [].slice.call(document.querySelectorAll('.j-spoiler-height'));

if (spoilerNodeArrayTwo.length) {
    spoilerNodeArrayTwo.forEach(spoilerNode => {
        const spoiler = new Spoiler();

        spoiler.init({
            target       : spoilerNode,
            height       : 180,
            blackout     : true,
            dynamicHeight: true
        });
    });
}

const block1 = document.querySelector('.j-block-abz1');
const block2 = document.querySelector('.j-block-abz2');

if (block1) {
    const url = window.location.pathname;

    if (url.match('/abz1/')) {
        block1.style.display = 'flex';
    }
}

if (block2) {
    const url = window.location.pathname;

    if (url.match('/dsk-abz-dorstroy/')) {
        block2.style.display = 'flex';
    }
}

// графики
const chartsArr = [].slice.call(document.querySelectorAll('.j-chart'));
/* eslint-disable */
if (chartsArr.length) {
    Chart.register(ChartDataLabels);
    // Кастомный плагин для отрисовки линий
    const arbitraryLine = {
        id: 'arbitraryLine',
        beforeDraw(chart, args, options) {
            const {ctx, chartArea: {top, right, bottom, left, width, height}, scales: {x, y}} = chart;
            // дальше магия и она работает
            const $datalabelsArr = chart.data.datasets[0].data;

            $datalabelsArr.forEach((item, index) => {
                ctx.save();
                ctx.strokeStyle = '#FF804D';
                // вычисляем какую высоту придать нашему прямоугольнику с шириной в 0
                const zero = y.getPixelForValue(item) - y.getPixelForValue(0);

                ctx.strokeRect(x.getPixelForValue(index), y.getPixelForValue(item), 0, -zero);
            });
        }
    };


    chartsArr.forEach(chart => {
        // создание градиента
        let $ctx = chart.getContext("2d");
        var canvasW = chart.width;
        var canvasH = chart.height;
        var gradientFill = $ctx.createLinearGradient(0, 0, 0, canvasH*1.05);
        gradientFill.addColorStop(0, "rgba(255, 128, 77,0.3)");
        gradientFill.addColorStop(1, "rgba(255, 255, 255,0.7)");


        const dataLabels = chart.dataset.labels;
        const dataDatasets = chart.dataset.datasets;
        const suggestedMin = Number(chart.dataset.suggestedmin);
        const suggestedMax = Number(chart.dataset.suggestedmax);
        let datasets = JSON.parse(dataDatasets);
        datasets[0]['fill'] = { target: 'origin',
                    above : gradientFill, // цвет зоны
                    below : 'rgba(255, 211, 194, 0.3)' // цвет зоны
                }

        // const dataConfig = chart.dataset.config;
        const config = {
            type: 'line',
            data: {
                // labels  : ['2016', '2017', '2018', '2021'],
                labels  : JSON.parse(dataLabels),

                datasets,
            },
            options: {
                responsive: true,
                title     : {display: false},
                tension   : 0.3,
                interaction: {
                    intersect: false,
                    mode: 'nearest'
                },
                scales    : {
                    y: {
                        suggestedMin, // Назначаем где начинаются значения на Y
                        suggestedMax, // Где заканчиваются
                        grid        : {
                            drawBorder: false,
                            color: function(context) {
                                if (context.tick.value === 0) {
                                    return '#FF804D'
                                } else {
                                    return 'rgba(0,0,0,0)';
                                }
                            },
                        },
                        ticks : {display: false}
                    },
                    x: {grid: {display: false},
                        ticks: {
                            color            : '#4D4D4D',
                            font             : {
                                size      : 16,
                                lineHeight: 1.2,
                                weight    : 'normal',
                                family    : '"ibm plex sans", sans-serif'
                            }
                        }
                    }
                },
                tooltip: {usePointStyle: true},
                plugins: {
                    legend    : {display: false},
                    // плагин чтобы выводить значения на график
                    datalabels: {
                        color(context) {
                            const value = context.dataset.data[context.dataIndex];

                            // максимальному значению меняем цвет
                            return value === context.dataset.data[context.dataset.data.length - 1] ? '#FF804D' : '#000000';
                        },
                        align : 'end',
                        anchor: 'end',
                        font  : {
                            // на разных разрешениях - разный размер текста
                            size  : window.outerWidth < 1024 ? 18 : 22,
                            weight: 'normal',
                            family: '"ibm plex sans", sans-serif'
                        }
                    }
                }
            },
            // подключение плагина
            plugins: [arbitraryLine]
        };
        // const $parse = JSON.stringify(config);

        // const dataConfig = chart.dataset.config;

        const myChart = new Chart(chart,
            config);
    });
}
/* eslint-enable */
// график большой
const bigChartsArr = [].slice.call(document.querySelectorAll('.j-chart-big'));

if (bigChartsArr.length) {
    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'row';
            listContainer.style.margin = 0;
            listContainer.style.padding = 0;
            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };
    // HTML - легенда над графиком
    const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
            const ul = getOrCreateLegendList(chart, options.containerID);

            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            // Reuse the built-in legendItems generator
            const items = chart.options.plugins.legend.labels.generateLabels(chart);

            items.forEach(item => {
                const li = document.createElement('li');

                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '15px';

                li.onclick = () => {
                    const {type} = chart.config;

                    if (type === 'pie' || type === 'doughnut') {
                        // Pie and doughnut charts only have a single dataset and visibility is per item
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                // Color box
                const boxSpan = document.createElement('span');

                boxSpan.style.background = item.hidden ? '#ffffff' : item.strokeStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = '1px';
                boxSpan.style.borderStyle = 'solid';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.height = '20px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '20px';

                // Text
                const textContainer = document.createElement('p');

                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;

                const text = document.createTextNode(item.text);

                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        }
    };

    const getOrCreateTooltip = (chart, color) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.borderRadius = '3px';
            tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table = document.createElement('table');

            table.style.margin = '0px';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        if (tooltipEl) {
            tooltipEl.style.background = color ? color : 'rgba(0, 0, 0, 0.7)';
        }


        return tooltipEl;
    };

    // eslint-disable-next-line max-lines-per-function,max-statements
    const externalTooltipHandler = context => {
        // Tooltip Element
        const {chart, tooltip} = context;

        const tooltipEl = getOrCreateTooltip(chart, tooltip.labelColors[0].borderColor);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;

            return;
        }
        // Set Text
        if (tooltip.body) {
            const bodyLines = tooltip.body.map(b => {
                return b.lines;
            });

            const tableHead = document.createElement('thead');

            const tableBody = document.createElement('tbody');

            // добавление кастомной data
            // eslint-disable-next-line no-underscore-dangle
            const addedData = tooltip.dataPoints[0].dataset.addedData;
            const dataLabels = chart.config._config.data.labels;

            if (addedData) {
                addedData.forEach(item => {
                    const index = dataLabels.indexOf(tooltip.title[0]);

                    if (index >= 0) {
                        const tr = document.createElement('tr');

                        tr.style.backgroundColor = 'inherit';
                        tr.style.borderWidth = 0;

                        const td = document.createElement('td');

                        td.style.borderWidth = 0;

                        td.innerHTML = `${item.title}: ${item.value[index]}`;
                        tr.appendChild(td);
                        tableBody.appendChild(tr);
                    }
                });
            }

            // добавляем в тултип стандартные данные
            bodyLines.forEach((body, i) => {
                const tr = document.createElement('tr');

                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');

                td.style.borderWidth = 0;

                const text = String(body).split(': ')[1];

                td.innerHTML = `Цена: ${text}`;
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
        tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;

    };
    // </block:external>


    bigChartsArr.forEach(chart => {
        const dataLabels = chart.dataset.labels;
        const dataDatasets = chart.dataset.datasets;
        const suggestedMin = Number(chart.dataset.suggestedmin);
        const suggestedMax = Number(chart.dataset.suggestedmax);

        const config = {
            type: 'line',
            data: {
                // Значения по X
                labels  : JSON.parse(dataLabels),
                // Каждый объект - отдельная линия на графике
                datasets: JSON.parse(dataDatasets)
            },
            options: {
                responsive : false, // для большого графика отключаем респонсив
                title      : {display: false}, // тайтл графика в канвасе
                tension    : 0.3, // плавность изгибов линий
                interaction: {
                    intersect: false,
                    // мод взаимодейсвия с графиком. Чтобы тултипы всплывали, когда курсор уже рядом
                    mode     : 'nearest'
                },
                scales: {
                    // если не делать объект "y" - график сам определить минимальные и максимальные значения
                    y: {
                        ticks: {
                            backdropColor    : '#F5F5F5',
                            backdropPadding  : 4,
                            padding          : 10,
                            color            : '#000000',
                            showLabelBackdrop: true,
                            font             : {
                                size      : 14,
                                lineHeight: 1.2,
                                weight    : 'bold',
                                family    : '"ibm plex sans", sans-serif'
                            },
                            z: 2
                        },
                        suggestedMin, // Назначаем где начинаются значения на Y
                        suggestedMax // Где заканчиваются
                    },
                    x: {
                        grid : {display: false},
                        ticks: {
                            backdropColor    : '#F5F5F5',
                            backdropPadding  : 7,
                            padding          : 30,
                            color            : '#000000',
                            showLabelBackdrop: true,
                            font             : {
                                size      : 14,
                                lineHeight: 1.2,
                                weight    : 'normal',
                                family    : '"ibm plex sans", sans-serif'
                            }
                        }
                    }
                },
                tooltip: {usePointStyle: true},
                plugins: {
                    // плагин для кастомной легенды
                    htmlLegend: {containerID: 'legend-container'},
                    legend    : {display: false // вкл/выкл стандартную легенду
                    },
                    tooltip: {
                        enabled : false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    }

                }
            },
            plugins: [htmlLegendPlugin] // подключение плагина
        };


        // eslint-disable-next-line no-unused-vars
        const myChart = new Chart(chart,
            config);
    });
}

// кружки в странице инвесторам
// eslint-disable-next-line max-len
const circlesChangers = Array.from(document.querySelectorAll('.j-circle-changer'));

if (circlesChangers.length) {
    const circleInvestor = new GroupCompany();


    circleInvestor.init({
        target: document.querySelector('.l-enterprises__info-desktop'),
        sides : circlesChangers.length
    });

    window.addEventListener('scroll', () => {
        const elemArr = [];

        circlesChangers.forEach(element => {
            const windowTop = window.pageYOffset;
            const sectionTop = element.offsetTop;

            const index = circlesChangers.indexOf(element);

            // eslint-disable-next-line no-magic-numbers
            if (windowTop > sectionTop - 600) {
                elemArr.push(index);
            }
        });

        const elemIndex = Math.max(...elemArr);
        const currentIndex = circleInvestor.getIndex();

        // eslint-disable-next-line no-undefined
        if (currentIndex === undefined || Number(currentIndex) !== elemIndex) {
            // eslint-disable-next-line no-magic-numbers
            if (elemIndex < 0) {
                return;
            }
            circleInvestor.setIndex(elemIndex);
            circleInvestor.changeImage(circlesChangers[elemIndex].dataset.image);
            circleInvestor.activateState();
            const logoSrc = circlesChangers[elemIndex].dataset.icon;

            circleInvestor.changeLogo(logoSrc);
            circleInvestor.toPart(elemIndex);
        }
    });
}

// Формы
const formWrappers = Array.from(document.querySelectorAll('.j-form'));

if (formWrappers.length) {
    formWrappers.forEach(wrapper => {
        initForm(wrapper);
    });
}

const documentsFilter = document.querySelector('.j-documents');

if (documentsFilter) {
    initDocumentsFilter({
        filter    : documentsFilter,
        isTabsForm: Boolean(document.querySelector('.j-tabs-wrap')),
        template  : documentsFilter.dataset.template
    });
}

// Табы
const tabs = Array.from(document.querySelectorAll('.j-tabs-wrap'));

if (tabs.length) {
    tabs.forEach(elem => {
        initTabs(elem);
    });
}

// Раскрытие списка табов на мобилке для смены таба
const category = document.querySelector('.j-change-category');

if (category) {
    const tabWrapper = document.querySelector('.j-change-category__wrapper');
    const closeCategory = document.querySelector('.j-change-category__close');
    const tabElements = document.querySelectorAll('.j-tabs__item');

    const show = () => {
        tabWrapper.classList.add('is-active');
        document.body.style.overflow = 'hidden';
    };

    const hide = () => {
        tabWrapper.classList.remove('is-active');
        document.body.style.removeProperty('overflow');
    };


    // eslint-disable-next-line arrow-body-style
    category.addEventListener('click', () => show());

    // eslint-disable-next-line arrow-body-style
    closeCategory.addEventListener('click', () => hide());

    tabElements.forEach(listElement => {
        // eslint-disable-next-line arrow-body-style
        listElement.addEventListener('click', () => hide());
    });
}

// Календарь
const calendars = Array.from(document.querySelectorAll('.j-calendar'));

calendars.forEach(elem => {
    const calendar = new Calendar();

    calendar.init(elem);
});
