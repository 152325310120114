import axios from 'axios';
import Mediator from 'common/scripts/mediator';
import Utils from 'common/scripts/utils';

const observer = new Mediator();

class Form {
    constructor() {
        this.inputList = [];
    }

    init(options) {
        this.wrapper = options.target;
        this.popupInstanse = options.popupInstanse;
        this.form = options.target.querySelector('form');
        this.isVaildation = 'validate' in this.form.dataset;
        this.successMessage = options.successMessage;
        this.action = this.form.getAttribute('action');
        this.messageBot = this.form.querySelector('[name="message_bot"]');

        this._getElements();
        this._subscribes();
        this._bindEvents();
        this._setHiddenInput();
        this._setHiddenUrl();
    }

    /**
     * Метод получает дом элементы.
     * @private
     */
    _getElements() {
        this.submit = this.form.querySelector('.j-form-submit');
        this.submitText = this.submit.querySelector('.b-button__text');

        if (this.successMessage) {
            this.successMessageWrap = this.wrapper.querySelector('.j-form-success');
            this.errorMessageWrap = this.wrapper.querySelector('.j-form-error');
        }
    }

    /**
     * Метод навешивает обработчики событий.
     * @private
     */
    _bindEvents() {
        if (this.isVaildation) {
            this.submit.addEventListener('click', (event) => {
                event.preventDefault();
                this._send();
            });
        }
    }

    /**
     * Метод содержит в себе коллбэки на события других модулей.
     * @private
     */
    _subscribes() {
        observer.subscribe('inputValidate', (input) => {
            this._changeState(input);
        });
    }

    /**
     * Метод оптправляет данные на сервер.
     * @private
     */
    _send() {
        if (!this.validateAll()) {
            return;
        }

        this._insertAntiSpam();
        this.formData = new FormData(this.form);
        this._insertButtonText('Отправляем...');

        axios.post(this.action, this.formData)
            .then((response) => {
                const status = response.data.request.status;

                if (status) {
                    this._sendIsSuccess();
                } else {
                    throw new Error('Ошибка на сервере');
                }
            })
            .catch((err) => {
                this._sendIsError();
                console.error(err);
            });
    }

    /**
     * Метод изменяет состояние валидации инпута при настпуплении соответствующего события.
     * @param {HTMLInputElement} input - инпут для которого нужно изменить состояние.
     * @private
     */
    _changeState(input) {
        this.inputList.forEach((item) => {
            if (item === input) {
                item.state = input.state;
            }
        });
    }

    /**
     * Метод запускает проверку валдиации для всех инпутов.
     * @return {boolean} - true/false успешная/неуспешная проверка.
     */
    validateAll() {
        let validate = true;

        this.inputList.forEach((item) => {
            if (!item.state) {
                item.throwError(item);
                validate = false;
            }
        });

        return validate;
    }

    /**
     * Метод вызывается при успешной отправке запроса.
     * @private
     */
    _sendIsSuccess() {
        Utils.hide(this.errorMessageWrap);
        this.submit.classList.remove('is-error');

        this._showSuccessMessage();
        this._insertButtonText('Отправлено');
        this.submit.classList.add('is-disabled');
        window.sessionStorage.removeItem('callback-init');
    }

    /**
     * Метод вызывается при ошибке отправки запроса.
     * @private
     */
    _sendIsError() {
        this._insertButtonText('Повторить отправку');
        this.errorMessageWrap.classList.add('is-active');
        this.submit.classList.add('is-error');

        this._showErrorMessage();
    }

    /**
     * Показывает сообщение об успешной отправкой формы.
     * @private
     */
    _showSuccessMessage() {
        Utils.show(this.successMessageWrap);
        this.successMessageWrap.classList.add('is-active');
        Utils.hide(this.form);

        observer.publish('successForm');
    }

    /**
     * Показывает сообщение об ошибке
     * @private
     */
    _showErrorMessage() {
        Utils.show(this.errorMessageWrap);
        Utils.hide(this.form);
    }

    /**
     * Метод закрывает все сообщения об отправке/неудаче
     */
    closeResultMessage() {
        Utils.hide(this.successMessageWrap);
        Utils.hide(this.errorMessageWrap);
        Utils.show(this.form);
    }

    /**
     * Метод вставляет текст в кнопку сабмит.
     * @param {string} text - необходимый текст.
     * @private
     */
    _insertButtonText(text) {
        if (this.submitText) {
            Utils.clearHtml(this.submitText);
            Utils.insetContent(this.submitText, text);
        }
    }

    /**
     * Метод вставляет скрытое поле с антиспамом
     */
    _insertAntiSpam() {
        if (this.messageBot) {
            const minRandom = 1;
            const maxRandom = 10;

            this.messageBot.value = `nospam ${Utils.random(minRandom, maxRandom)}`;
        }
    }

    /**
     * Метод сбразывает поля формы
     * @private
     */
    _resetForm() {
        this.inputList.forEach((item) => {
            item.clear();
        });

        this.form.reset();
    }

    /**
     * Устанавливает данные скрытому инпуту исходя из таргета попапа
     * @private
     */
    _setHiddenInput() {
        if (this.popupInstanse) {
            const input = this.popupInstanse.target.dataset.input;

            if (input) {
                const data = input.split(':');

                this.form.querySelector(`input[name=${data[0]}]`).value = data[1];
            }
        }
    }

    /**
     * При необходимости устанавливает текущий url в скрытый инпут
     * @private
     */
    _setHiddenUrl() {
        const input = this.form.querySelector('input[name=url]');

        if (input) {
            input.value = window.location.href;
        }
    }
}

export default Form;

