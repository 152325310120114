import $ from 'jquery';

/**
 * @version 1.0
 * @author Kelnik Studios {http://kelnik.ru}
 * @link https://kelnik.gitbooks.io/kelnik-documentation/content/ documentation
 */

/**
 * DEPENDENCIES
 */

class Utils {
    /**
     * Метод устанавливает комфорную задержку выполнения анимации.
     * @return {Number} comfortableAnimationTimeValue - значение в мс.
     */
    static comfortableAnimationTime() {
        const comfortableAnimationTimeValue = 300;

        return comfortableAnimationTimeValue;
    }

    /**
     * Метод полностью очищает весь html элемент.
     * @param {Object} element - DOM-элемент, который необходимо очистить.
     */
    static clearHtml(element) {
        element.innerHTML = '';
    }

    /**
     * Метод вставляет содержимое в блок.
     * @param {Object} element - элемент в который нужно вставить.
     * @param {Object/string} content - вставляемый контент.
     */
    static insetContent(element, content) {
        if (typeof content === 'string') {
            element.insertAdjacentHTML('beforeend', content);
        } else if (typeof content === 'object') {
            element.appendChild(content);
        }
    }

    /**
     * Метод полностью удаляет элемент из DOM-дерева.
     * @param {Object} element - элемент, который необходимо удалить.
     */
    static removeElement(element) {
        // node.remove() не работает в IE11
        element.parentNode.removeChild(element);
    }


    /**
     * Метод показывает элемент.
     * @param {Node} element - элемент, который необходимо показать.
     */
    static show(element) {
        element.style.display = 'block';
    }

    /**
     * Метод скрывает элемент.
     * @param {Node} element - элемент, который необходимо скрыть.
     */
    static hide(element) {
        element.style.display = 'none';
    }

    /**
     * Метод отправляет ajax запрос на сервер.
     * @param {Object} data - отправляемые данные.
     * @param {String} url - маршрут по которому нужно произвести запрос.
     * @param {Function} callback -  функция обратного вызова, которая при успехе вызовет success, а при ошибке error.
     */
    static send(data, url, callback = function() {}) {
        const xhr = new XMLHttpRequest();
        const statusSuccess = 200;
        let dataToSend = data;

        xhr.open('POST', url);

        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.setRequestHeader('x-requested-with', 'XMLHttpRequest');

        if (typeof dataToSend === 'object') {
            dataToSend = $.param(dataToSend);
        }

        xhr.send(dataToSend);

        xhr.onload = function XHR() {
            if (xhr.status === statusSuccess) {
                const req = JSON.parse(this.responseText);

                callback.success(req);
            } else {
                callback.error(xhr.status);
            }
        };
    }

    /**
     * Метод проверяет наличие интернета
     * @return {boolean} - При наличии результатом будет true, а при отсутсвии false.
     */
    static checkInternetConnection() {
        return navigator.onLine;
    }

    /**
     * Метод проверяет присутствует ли ключ в объекте
     * @param {Object} object - проверяем объект
     * @param {String} key - ключ, наличие которого проверяет в объекте
     * @return {boolean} - присутствует или нет ключ в объекте
     */
    static keyExist(object, key) {
        return Object.prototype.hasOwnProperty.call(object, key);
    }

    /**
     * Метод проверяет пустой объект или нет
     * @param {Object} object - объект проверяемый на пустоту
     * @return {boolean} - true если пустой и false если полный
     */
    static isEmpryObject(object) {
        const empty = 0;

        return Object.keys(object).length === empty;
    }

    /**
     * Проверяет переданные данные на строку
     * @param {String} string - данные на проверку
     * @return {boolean} - возращает true, если строка, и false наоборот
     */
    static isString(string) {
        return typeof string === 'string';
    }

    /**
     * Узнает index элемента в родительской элемент
     * Аналог jquery.index()
     * @param {Node} element - искомый элемент
     * @return {number} - порядковый номер (индекс) в родительском элементе
     */
    static getElementIndex(element) {
        return Array.from(element.parentNode.children).indexOf(element);
    }

    /**
     * Генерация псевдослучайных чисел в заданном  числовом интервале
     * @param {Number} min - минимальное псевдослучайное число
     * @param {Number} max - максимальное псевдослучайное число
     * @return {Number} - возвращает псевдослучайное число в интервале [min, max]
     */
    static random(min, max) {
        return Math.floor((Math.random() * (max - min)) + min);
    }
}

export default Utils;
