class IndexInfo {
    constructor(options) {
        this.elementArray = options.elements;
        this.animationTime = 300;
    }

    change(numberSlider) {
        const step = 1;
        const indexElem = numberSlider - step;

        this.elementArray.forEach((item, index) => {
            if (index === indexElem) {
                setTimeout(() => {
                    item.style.display = 'block';
                    item.style.opacity = '1';
                }, this.animationTime);

                return;
            }

            item.style.opacity = 0;
            setTimeout(() => {
                item.style.display = 'none';
            }, this.animationTime);
        });
    }
}

export default IndexInfo;
