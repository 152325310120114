// Зависимости
import Mediator from 'common/scripts/mediator';

const mediator = new Mediator();

// Компонент
class MobileMenu {
    /**
     * @constructor
     *
     */
    constructor() {
        /**
         * Состояние меню
         * открытое - open
         * закрытое - show
         * @type {string}
         */
        this.state = 'close';

        /**
         * DOM node меню
         * @type {null}
         */
        this.target = null;

        /**
         * Класс открытого меню
         * @type {string}
         */
        this.classOpen = 'b-mobile-menu_is_open';

        /**
         * Класс закрытого меню
         * @type {string}
         */
        this.classClose = 'b-mobile-menu_is_close';
    }

    /**
     *
     * @param {Object} options - outer options
     */
    init(options) {
        this.target = options.target;
    }

    /**
     * Изменяет состояние меню с открытого на закрытое и наоборот
     */
    changeState() {
        if (this.state === 'open') {
            this.close();
        } else {
            this.open();
        }
    }

    /**
     * Открывает меню
     */
    open() {
        this.state = 'open';
        this.target.classList.add(this.classOpen);
        this.target.classList.remove(this.classClose);

        mediator.publish('mobileMenu:open');
    }

    /**
     * Закрывает меню
     */
    close() {
        this.state = 'close';
        this.target.classList.add(this.classClose);
        this.target.classList.remove(this.classOpen);

        mediator.publish('mobileMenu:close');
    }
}

export default MobileMenu;
