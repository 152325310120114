class HistoryToggle {
    constructor() {
        this.activeClass = 'b-history-feed__list_is_open';
        this.textToOpen = '';
        this.textToHide = '';
    }

    init(options = {}) {
        this.textToOpen = options.textToOpen || 'Вся история';
        this.textToHide = options.textToHide || 'Свернуть';

        this.bindClick();
    }

    bindClick() {
        const content = document.querySelector('.b-history-feed__list');
        const toggle = document.querySelector('.b-history-feed__toggle');
        const textElement = toggle.querySelector('.b-history-feed__toggle .b-button__text');

        toggle.addEventListener('click', () => {
            if (content.classList.contains(this.activeClass)) {
                textElement.innerText = this.textToOpen;
                content.classList.remove(this.activeClass);
            } else {
                textElement.innerText = this.textToHide;
                content.classList.add(this.activeClass);
            }
        });
    }
}

export default HistoryToggle;
